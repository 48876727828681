import Service from '@/helpers/service'

export default {
  endUserWorkBenchMaterial(page, params) {
    return Service().get("workbench-end-user-search-material?page=" + page, {
      params,
    });
  },
  workBenchMaterial(params) {
    return Service().get("workbench-search-material", { params });
  },
  updateWorkBenchMaterial(params, id) {
    return Service().post("workbench-search-material/update/" + id, params);
  },
  multipleUpdateCatStatus(params) {
    return Service().post("workbench-update-cat-status-all", params);
  },
  getMaterialNumber(params) {
    return Service().get("workbench-get-material-number", { params });
  },
  cloneAllData(params) {
    return Service().post("workbench-clone-data", params);
  },
  copyAllValue(params) {
    return Service().post("workbench-copy-value", params);
  },
  getCharacteristic(material_number) {
    return Service().get("workbench-part-charactreristic/" + material_number);
  },
  searchByCharacteristic(page, params) {
    return Service().get("advance-search-characteristic?page=" + page, {
      params,
    });
  },
  dataKPI(params) {
    return Service().get("workbench-kpi", { params });
  },
  dashboardGraphic(params) {
    return Service().get("workbench-dashboard-graphic", { params });
  },
  dashboardRaw() {
    return Service().get("workbench-dashboard-raw");
  },
  dashboardWork() {
    return Service().get("workbench-dashboard-work");
  },
  dashboardTarget() {
    return Service().get("workbench-dashboard-target");
  },
  dashboardCatStatus() {
    return Service().get("workbench-dashboard-catalog-status");
  },
  workbench_config(page, params) {
    return Service().get("workbench-config?page=" + page, { params });
  },
  workbench_config_edit(params) {
    return Service().post("workbench-config-edit", params);
  },
  workbench_filter_end_user_config(params) {
    return Service().post("workbench-filter-user-config", params);
  },
  get_opted_colomn_config_user_on_end_user() {
    return Service().get("workbench-get-opted-column-config-user");
  }
};
