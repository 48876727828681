import Service from '@/helpers/service'

export default {

    get(params) {
        return Service().get('SmartcatAttachment', {params});
    },
    create_folder(params) {
        return Service().post('SmartcatAttachment/create-folder', params);
    },
    rename_folder(id, params) {
        return Service().post('SmartcatAttachment/rename-folder/'+id, params);
    },
    delete_folder(id) {
        return Service().post('SmartcatAttachment/delete-folder/'+id);
    },
    detail_folder(id, params) {
        return Service().get('SmartcatAttachment/detail_folder/'+id, {params});
    },
    upload_file(params) {
        return Service().post('SmartcatAttachment/upload-file', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    rename_file(id, params) {
        return Service().post('SmartcatAttachment/rename_file/'+id, params);
    },
    delete_file(id) {
        return Service().post('SmartcatAttachment/delete_file/'+id);
    },
}