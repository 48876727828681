import Service from '@/helpers/service'

export default {

    getDraftList(page, params) {
        return Service().get('emcr/draft?page=' + page,  {params});
    },
    getMcrHeader(mcr_code, token) {
        return Service().get('emcr/mcr-header/'+mcr_code+'/'+token);
    }, 
    getMcrItem(mcr_code, token, params) {
        return Service().get('emcr/mcr-item/'+mcr_code+'/'+token, {params});
    },
    getTotalMcrItem(mcr_code, token) {
        return Service().get('emcr/total-mcr-item/'+mcr_code);
    },
    updateMcrSubject(mcr_code, token, params) {
        return Service().post('emcr/update/'+mcr_code+'/'+token, params);
    }, 
    deleteMcr(mcr_code, token) {
        return Service().post('emcr/delete/'+mcr_code+'/'+token);
    },    
    createNewMcrItem(params) {
        return Service().post('emcr/create-item', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    createNewMcrItemNoFormData(params) {
        return Service().post('emcr/create-item', params);
    },    
    updateMcrItem(params, id) {
        return Service().post('emcr/update-item/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateMcrItemNoFormData(params, id) {
        return Service().post('emcr/update-item/'+id, params);
    },
    getDetailMcrItem(mcr_item_code) {
        return Service().get('emcr/detail-mcr-item/'+mcr_item_code);
    },
    getMcrItemMpn(mcr_item_code) {
        return Service().get('emcr/mcr-item-mpn/'+mcr_item_code);
    },
    getMcrItemEquipment(mcr_item_code) {
        return Service().get('emcr/mcr-item-equipment/'+mcr_item_code);
    },
    getMcrItemAttachment(mcr_item_code) {
        return Service().get('emcr/mcr-item-attachment/'+mcr_item_code);
    },
    getMcrItemParameter(mcr_item_code) {
        return Service().get('emcr/mcr-item-parameter/'+mcr_item_code);
    },
    getMcrItemConsolidation(mcr_item_code) {
        return Service().get('emcr/mcr-item-consolidation/'+mcr_item_code);
    },
    getMcrItemConsolidateTo(mcr_item_code) {
        return Service().get('emcr/mcr-item-consolidate-to/'+mcr_item_code);
    },
    getPrcMcrItemCharacteristicValue(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-characteristic-value/'+mcr_item_code);
    },
    getPrcMcrItemPoText(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-po-text/'+mcr_item_code);
    },
    getPrcMcrItemMpn(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-manufacture-ref/'+mcr_item_code);
    },
    getPrcMcrItemPlant(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-plant/'+mcr_item_code);
    },
    getPrcMcrItemStorageLocation(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-storage-location/'+mcr_item_code);
    },
    getPrcMcrItemSales(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-sales/'+mcr_item_code);
    },
    getPrcMcrItemMrp(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-mrp/'+mcr_item_code);
    },
    getPrcMcrItemAccounting(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-accounting/'+mcr_item_code);
    },
    getPrcMcrItemPurchasing(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-purchasing/'+mcr_item_code);
    },
    getPrcMcrItemCosting(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-costing/'+mcr_item_code);
    },
    getPrcMcrItemEquipment(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-equipment/'+mcr_item_code);
    },
    getPrcMcrItemKeyword(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-keyword/'+mcr_item_code);
    },
    getPrcMcrItemAttachment(mcr_item_code) {
        return Service().get('emcr/prc-mcr-item-attachment/'+mcr_item_code);
    },
    deleteMcrItem(id) {
        return Service().post('emcr/delete-item/'+id);
    },
    show(id) {
        return Service().get('emcr/show/'+id);
    },    
    create(params) {
        return Service().post('emcr/create', params);
    },
    update(id, params) {
        return Service().post('emcr/update/'+id, params);
    },
    delete(id) {
        return Service().post('emcr/delete/'+id);
    },
    log_approval(mcr_code) {
        return Service().get('/emcr/log_approval/'+mcr_code);
    },
    getMcrItemNote(mcr_item_code) {
        return Service().get('/emcr/mcr-item-note/'+mcr_item_code);
    },
    checkRevisedItem(mcr_code) {
        return Service().get('/emcr/revised-item/'+mcr_code);
    },
    sendMcr(mcr_code) {
        return Service().post('emcr/send-mcr/'+mcr_code);
    },
    getParameterMcr() {
        return Service().get('emcr/parameter-mcr');
    },
    deleteMcrItemParameter(id) {
        return Service().post('emcr/delete-mcr-item-parameter/'+id);
    },
    deleteMcrItemAttachment(id) {
        return Service().post('emcr/delete-mcr-item-attachment/'+id);
    },
    import_mcr_item(mcr_code, params) {
        return Service().post('emcr/import-mcr-item/'+mcr_code, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    get_emcr_from_item_mcr(key) {
        return Service().get('emcr/get-emcr-from-item-mcr/'+key);
    },
}