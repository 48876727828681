<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>      
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <div class="card-body text-center">
          <img src="img/other/expired.png" width="500" alt="Responsive image" class="img-fluid">
          <p>Your MCR is expired.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'expiredpage'
    };
</script>