const config = {
  // apiUrl: "http://localhost/smartcat_sst/smartcat-api-sst/public/",
  // storageUrl: "http://localhost/smartcat_sst/smartcat-api-sst/storage/",

  // apiUrl: "http://localhost/smartcat/smartcat-api-sst/public/",
  // storageUrl: "http://localhost/smartcat/smartcat-api-sst/storage/",

  // apiUrl: "http://localhost/sst/smartcat-api-sst/public/",
  // storageUrl: "http://localhost/sst/smartcat-api-sst/storage/",

  apiUrl: "https://smartcatapi.sinergiteknik.co.id/new/public/",
  storageUrl: "https://smartcatapi.sinergiteknik.co.id/new/storage/",
};

export default config 