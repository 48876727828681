import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('DrawingMaster?page=' + page, {params});
    },
    getAttachment(drawingNumber) {
        return Service().get('DrawingMaster/attachment/' + drawingNumber);
    }, 
    show(id) {
        return Service().get('DrawingMaster/show/'+id);
    },    
    create(params) {
        return Service().post('DrawingMaster/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('DrawingMaster/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('DrawingMaster/delete/'+id);
    }
}