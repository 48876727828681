import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('ItemName?page='+page, {params});
    }, 
    getByItemName(id){
        return Service().get('ItemName/data/by/' + id)
    },
    data(id) {
        return Service().get('ItemName/data/'+id);
    },
    show(id) {
        return Service().get('ItemName/show/'+id);
    },        
    create(params) {
        return Service().post('ItemName/create_item_name', params);
    },
    update(id, params) {
        return Service().post('ItemName/update_item_name/'+id, params);
    },
    delete(id) {
        return Service().post('ItemName/delete_item_name/'+id);
    },
    group_class_def(id) {
        return Service().get('ItemName/group_class_def/'+id);
    },
    add_colloquial(params) {
        return Service().post('ItemName/add_colloquial', params);
    },
    delete_colloquial(id) {
        return Service().post('ItemName/delete_colloquial/'+id);
    },
    add_group_class(params) {
        return Service().post('ItemName/add_group_class', params);
    },
    delete_group_class(id) {
        return Service().post('ItemName/delete_group_class/'+id);
    },
    add_material_type(params) {
        return Service().post('ItemName/add_material_type', params);
    },
    delete_material_type(id) {
        return Service().post('ItemName/delete_material_type/'+id);
    },
    add_material_group(params) {
        return Service().post('ItemName/add_material_group', params);
    },
    delete_material_group(id) {
        return Service().post('ItemName/delete_material_group/'+id);
    },
    add_service_category(params) {
        return Service().post('ItemName/add_service_category', params);
    },
    delete_service_category(id) {
        return Service().post('ItemName/delete_service_category/'+id);
    },
    add_service_group(params) {
        return Service().post('ItemName/add_service_group', params);
    },
    delete_service_group(id) {
        return Service().post('ItemName/delete_service_group/'+id);
    },
    add_image_item_name(params) {
        return Service().post('ItemName/add_image_item_name', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete_image_item_name(id) {
        return Service().post('ItemName/delete_image_item_name/'+id);
    },
    generate_inc() {
        return Service().get('ItemName/generate_inc');
    },
    add_mesc(params){
        return Service().post('ItemName/add_mesc', params);
    },
    delete_mesc(inc,key){
        return Service().post('ItemName/mesc/delete/'+inc+'/'+key)
    },
    add_mesc(params) {
        return Service().post("ItemName/add_mesc", params);
    },
    delete_mesc(inc, key) {
        return Service().post("ItemName/mesc/delete/" + inc + "/" + key);
    },
    get_char(inc, key) {
        return Service().get("Mesc/Item-Name/Characteristic/" + inc + '/' + key);
    },
    save_char_mesc(inc, params) {
        return Service().post("Mesc/Item-Name/Save-Characteristic/" + inc, params);
    },
    save_unspsc(inc, params){
        return Service().post("ItemName/unspsc/add/"+inc, params)
    },
    children_unspsc(inc, parent){
        return Service().get("ItemName/unspsc/children/"+inc+"/"+parent)
    }
}