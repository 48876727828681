<template>
    <div>
        <div v-if="authUserPermission['smartcat-attachment-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-3">
                                <button @click="detail(previousFolder)" v-if="folderId != 0" class="btn btn-outline-primary btn-sm font-weight-bold text-muted"><i class="fas fa-long-arrow-alt-left"> Back</i></button>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_attachment')" v-on:keyup="filter">
                                </div>

                                <!-- input untuk nyimpen link url attachment -->
                                <input type="hidden" ref="attachmentLink" v-model="urlAttachment">
                            </div>
                            <div class="col-3 text-right">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-create-folder'] || authUserPermission['smartcat-attachment-create-file']">
                                    <base-button size="sm">{{ tt('add_new') }}</base-button>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'new_folder'}" v-if="authUserPermission['smartcat-attachment-create-folder']">{{ tt('new_folder') }}</el-dropdown-item>                             
                                        <el-dropdown-item :command="{action:'new_file'}" v-if="authUserPermission['smartcat-attachment-create-file']">{{ tt('new_file') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'new_link'}">{{ tt('new_link') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>

                    <skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

                    <div class="table-responsive" style="height: 350px" v-else>
                        <table class="table table-flush" v-if="DataAttachment.data.folder.length > 0 || DataAttachment.data.file.length > 0">
                            <thead class="thead-light">
                                <th>{{ tt('name') }}</th>
                                <th>{{ tt('upload_by')}}</th>
                                <th>{{ tt('created_at') }}</th>
                                <th>{{ tt('size') }}</th>
                                <th width="5"></th>
                            </thead>
                            <tbody v-for="(folder) in DataAttachment.data.folder" :key="'folder'+ folder.id">
                                <td @click="detail(folder.folder_attachment_code)">
                                    <span style="cursor: pointer"><i class="fa fa-folder text-warning pr-10 mr-4"></i>{{ folder.folder_name }}</span>
                                </td>
                                <td>{{ folder.name }}</td>
                                <td>{{ folder.created_at }}</td>
                                <td></td>
                                <td>
                                    <template>
                                        <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-rename-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                            <span class="btn btn-sm btn-icon-only text-light">
                                                <i class="fas fa-ellipsis-v mt-2"></i>
                                            </span>
                                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                <el-dropdown-item :command="{action:'rename_folder', id:folder.id, name:folder.folder_name}" v-if="authUserPermission['smartcat-attachment-rename-folder']" >{{ tt('rename') }}</el-dropdown-item>                             
                                                <el-dropdown-item :command="{action:'delete_folder', data:folder.folder_attachment_code}" v-if="authUserPermission['smartcat-attachment-delete-folder']">{{ tt('delete') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'move_to', data:folder.id}" v-if="authUserPermission['smartcat-attachment-moveto-folder']">{{ tt('move_to') }}</el-dropdown-item>                                        
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </td>
                            </tbody>
                            <tbody v-for="(file) in DataAttachment.data.file" :key="'file' + file.id">
                                <td> 
                                    <i class="fa fa-file mr-4" v-if="file.type_of_file !== 'link'"></i>

                                    <i class="fas fa-globe mr-4" v-else-if="file.type_of_file === 'link'"></i>

                                    <a :href="apiUrl+'smartcat_attachment/'+ file.file_attachment_name + '.' + file.type_of_file" v-if="file.type_of_file != 'link'" target="_blank">
                                        {{ file.file_attachment_name }}.{{ file.type_of_file }}
                                    </a>
                                    <a :href="file.file_attachment_name" v-else target="_blank">
                                        {{ file.file_attachment_name }}
                                    </a>
                                </td>
                                <td>{{ file.name }}</td>
                                <td>{{ file.created_at }}</td>
                                <td>{{ file.file_size }}KB</td>
                                <td>
                                    <template>
                                        <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-rename-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                            <span class="btn btn-sm btn-icon-only text-light">
                                                <i class="fas fa-ellipsis-v mt-2"></i>
                                            </span>
                                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                <el-dropdown-item :command="{action:'rename_file', data:file.id, old_name: file.file_attachment_name, type_of_file: file.type_of_file}" v-if="authUserPermission['smartcat-attachment-update-file']">{{ tt('rename') }}</el-dropdown-item>                             
                                                <el-dropdown-item :command="{action:'delete_file', data:file.file_attachment_code}" v-if="authUserPermission['smartcat-attachment-delete-file']">{{ tt('delete') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'move_to', data:file.id}" v-if="authUserPermission['smartcat-attachment-moveto-file']">{{ tt('move_to') }}</el-dropdown-item>   
                                                <el-dropdown-item :command="{action:'copy_link', data:file.file_attachment_name +'.'+ file.type_of_file, type:file.type_of_file}" v-if="file.type_of_file != 'link'">{{ tt('choose_data') }}</el-dropdown-item>  
                                                <el-dropdown-item :command="{action:'copy_link', data:file.file_attachment_name, type:file.type_of_file}" v-if="file.type_of_file == 'link'">{{ tt('choose_data') }}</el-dropdown-item>  
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </td>
                            </tbody>
                        </table>
                        <div class="text-center" style="height: 100px" v-else>
                            <br><br>
                            <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="350px">
                            <p class="text-muted">{{ tt('empty') }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formFile.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('upload_files') }}</h5>
                    </template>
                    <div>
                        <div v-if="formFile.add">
                            <label class="form-control-label">{{ tt('file_name') }} <span class="text-danger">*</span></label>
                            <file-input @change="filesChange" ref="file"></file-input>
                        </div>
                        <base-dropdown class="nav-item ml--2 mt-3" tag="li" title-classes="nav-link" title-tag="a" :title="tt('file_name_option')" menu-classes="dropdown-menu-sm dropdown-menu-right py-0 overflow-hidden">
                            <template>                         
                                <div class="list-group list-group-flush">
                                    <a class="list-group-item list-group-item-action" @click="file_name_option('freetext')">
                                        {{ tt('freetext') }}
                                    </a>
                                    <a class="list-group-item list-group-item-action" @click="file_name_option('oem')">
                                        {{ tt('oem') }}
                                    </a>
                                    <a class="list-group-item list-group-item-action" @click="file_name_option('general')">
                                        {{ tt('general') }}
                                    </a>
                                </div>
                            </template>
                        </base-dropdown>
                        <hr class="mt-2">
                        <div v-if="fileNameOption == 'freetext'">
                            <label class="form-control-label">{{ tt('file_name') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('file_name')" :placeholder="tt('file_name')" v-model="smartcatAttachment.file.file_attachment_name" rules="required"></base-input>
                        </div>
                        <div v-else-if="fileNameOption == 'oem'">
                            <!-- EQUIPMENT NAME -->
                            <label class="form-control-label">{{ tt('equipment_name') }} <span class="text-danger">*</span></label>
                            <!-- <base-input :name="tt('equipment_name')" :placeholder="tt('equipment_name')" v-model="smartcatAttachment.file.equipment_name" rules="required"></base-input> -->
                            <base-input :name="tt('equipment_name')" rules="required">
                                <el-select :disabled="isClicked == false" v-model="smartcatAttachment.file.equipment_name" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchEquipment" :loading="selectSearch.equipmentLoading" filterable remote reserve-keyword>
                                    <el-option :label="ec.equipment_code + '-' +ec.equipment_description" :value="ec.equipment_code" v-for="ec in equipmentList" :key="'equipment' + ec.value"></el-option>
                                </el-select>
                            </base-input>
                            <!-- MANUFACTURE CODE -->
                            <label class="form-control-label">{{ tt('manufacture_code') }} <span class="text-danger">*</span></label>
                            <!-- <base-input :name="tt('manufacture_code')" :placeholder="tt('manufacture_code')" v-model="smartcatAttachment.file.manufacture_code" rules="required"></base-input> -->
                            <base-input :name="tt('manufacture_code')"  rules="required">
                                <el-select v-model="smartcatAttachment.file.manufacture_code" :placeholder="tt('minimum_type_char', {total:2})" :remote-method="querySearchManCode" :loading="selectSearch.mancodeLoading" filterable remote reserve-keyword>
                                    <el-option v-for="mancode in masterManufacture" :value="mancode.manufacture_code" :key="mancode.id" :label="mancode.manufacture_code + ' - ' + mancode.manufacture_name" :selected="mancode.manufacture_code"></el-option>
                                </el-select>
                            </base-input>
                            <!-- MODEL OR TYPE -->
                            <label class="form-control-label">{{ tt('model_or_type') }} <span class="text-danger">*</span></label>
                            <!-- <base-input :name="tt('model_or_type')" :placeholder="tt('model_or_type')" v-model="smartcatAttachment.file.model_or_type" rules="required"></base-input> -->
                            <base-input :name="tt('material_type')" rules="required">
                                <el-select v-model="smartcatAttachment.file.model_or_type" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchMtype" :loading="selectSearch.mtLoading" filterable remote reserve-keyword>
                                    <el-option class="select-danger" :value="mt.description" :label="mt.code+' - '+mt.description" :key="mt.code" v-for="mt in  materialType"></el-option>
                                </el-select>
                            </base-input>
                            <!-- PART NUMBER OR DRAWING -->
                            <label class="form-control-label">{{ tt('part_number_or_drawing') }} <span class="text-danger">*</span></label>
                            <!-- <base-input :name="tt('part_number_or_drawing')" :placeholder="tt('part_number_or_drawing')" v-model="smartcatAttachment.file.part_number_or_drawing" rules="required"></base-input> -->
                            <base-input :name="tt('part_number_or_drawing')"   rules="required">    
                                <el-select v-model="smartcatAttachment.file.part_number_or_drawing" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchDrawingNumber" :loading="selectSearch.drawingNumLoading" filterable remote reserve-keyword>
                                    <el-option :label="dm.drawing_number +' - '+ dm.drawing_name" :value="dm.drawing_number" v-for="dm in drawingMaster" :key="'equipment' + dm.id"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div v-else>
                            <!-- INC -->
                            <label class="form-control-label">{{ tt('inc') }} <span class="text-danger">*</span></label>
                            <!-- <base-input :name="tt('inc')" :placeholder="tt('inc')" v-model="smartcatAttachment.file.inc" rules="required"></base-input> -->
                            <base-input :name="tt('inc')" :placeholder="tt('inc')" rules="required">
                                <el-select  v-model="smartcatAttachment.file.inc" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchItemName" :loading="selectSearch.sitLoading" filterable remote reserve-keyword> 
                                    <el-option v-for="si in standardItemName" :key="si.inc" :value="si.inc" :label="si.inc +' - '+ si.item_name"></el-option>
                                </el-select>
                            </base-input>
                            <!-- MFR CODE -->
                            <label class="form-control-label">{{ tt('mfr_code') }} <span class="text-danger">*</span></label>
                            <!-- <base-input :name="tt('mfr_code')" :placeholder="tt('mfr_code')" v-model="smartcatAttachment.file.mfr_code" rules="required"></base-input> -->
                            <base-input :name="tt('mfr_code')" :placeholder="tt('mfr_code')"  rules="required">
                                <el-select v-model="smartcatAttachment.file.mfr_code" :placeholder="tt('minimum_type_char', {total:2})" :remote-method="querySearchManCode" :loading="selectSearch.mancodeLoading" filterable remote reserve-keyword>
                                    <el-option v-for="mancode in masterManufacture" :value="mancode.manufacture_code" :key="mancode.id" :label="mancode.manufacture_code + ' - ' + mancode.manufacture_name" :selected="mancode.manufacture_code"></el-option>
                                </el-select>
                            </base-input>
                            <!-- MANREF -->
                            <label class="form-control-label">{{ tt('manref') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('manref')" :placeholder="tt('manref')" v-model="smartcatAttachment.file.manref" rules="required"></base-input>
                            <!-- <base-input :name="tt('manref')" :placeholder="tt('manref')" v-model="smartcatAttachment.file.manref" rules="required">
                                <el-select   v-model="smartcatAttachment.file.manref"  :placeholder="tt('choose_manref_type')" >
                                    <el-option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id" :label="mt.code +' - '+ mt.description"></el-option>
                                </el-select>
                            </base-input> -->
                        </div>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formFile.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveFile" :disabled="btnSaveFile.onLoading || invalid">
                            <span v-if="btnSaveFile.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formFile.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formFolder.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ formFolder.title }}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="(message, index) in errorMessage.data" :key="index">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('folder_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('folder_name')" :placeholder="tt('folder_name')" v-model="smartcatAttachment.folder.folder_name" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formFolder.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveFolder" :disabled="btnSaveFolder.onLoading || invalid">
                            <span v-if="btnSaveFolder.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formFolder.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formLink.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ formLink.title }}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="(message, index) in errorMessage.data" :key="index">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('link_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('link_name')" :placeholder="tt('link_name')" v-model="smartcatAttachment.link.file_attachment_name" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formLink.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveLink" :disabled="btnSaveLink.onLoading || invalid">
                            <span v-if="btnSaveLink.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formLink.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <modal :show.sync="formCopyLink.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('copy_link') }}</h5>
                </template>
                <div>
                    <div class="input-group">
                      <input type="text" class="form-control" ref="attachmentLink" v-model="urlAttachment">
                      <div class="input-group-append">
                        <button class="btn border" type="button" @click="copy_link">
                            <i class="fa fa-clipboard" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                </div>
            </modal>
            
        </div>
        <noaccess v-else/>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Api from '@/helpers/api';
import smartcatAttachment from '@/services/dictionary/smartcatAttachment.service';
import baseApiUrl from '@/configs/config';
import standardItemName from '@/services/dictionary/standardItemName.service';
import manrefType from '@/services/master/manrefType.service';
import manufacture from '@/services/master/manufacture.service';
import equipmentCode from '@/services/master/equipmentCode.service';
import drawingMaster from '@/services/dictionary/drawingMaster.service';
import materialType from '@/services/master/materialType.service';

export default {
    name: 'SmartCatAttachment',
    data(){
        return {
            requestCalls: 0,
            folderId:0,
            parentCode:0,
            previousFolder:0,
            apiUrl: baseApiUrl.storageUrl,
            DataAttachment:{
                data:{
                    folder:[],
                    file:[]
                }
            },
            selectSearch: {
                sitLoading: false,
                mancodeLoading:false,
                equipmentLoading:false,
                drawingNumLoading:false,
                mtLoading:false,
                loadTimeout: null
            },
            btnSaveFolder: {
                onLoading: false
            }, 
            fileNameOption:'freetext',
            table: {
                search:null
            },
            btnSaveFile: {
                onLoading: false
            },
            btnSaveLink: {
                onLoading: false
            },
            formFolder: {
                title : this.tt('add_new_folder'),
                show:false
            },
            formFile : {
                title : this.tt('add_new_file'),
                show:false
            },
            formLink : {
                title : this.tt('add_new_link'),
                show:false
            },
            formCopyLink : {
                show:false
            },
            smartcatAttachment: {
                folder: {
                    folder_name : null,
                    parent_code : null,
                },
                file: {},
                link:{
                    folder_attachment_code:null,
                    file_attachment_code:null,
                    type_of_file:'link',
                    file_attachment_name:null,
                }
            },
            errorMessage: null,
            renameFile:{
                file: {
                    file_attachment_code:null,
                    old_name:null,
                    type_of_file:null,
                }
            },
            urlAttachment: '',
            standardItemName: {},
            manrefType:{},
            masterManufacture:{},
            equipmentList:{},
            drawingMaster:{},
            materialType: {},
        }
    },
    computed:{
        ...mapState({
            authUserPermission :state => state.auth.userPermission
        })
    },
    mounted(){
        this.get(0);
        this.getManrefType()
    },
    methods: {
        get(id){
            this.requestCalls++;

            let context = this;
            // let previousFolder = null
            Api(context, smartcatAttachment.detail_folder(id, {search: this.table.search})).onSuccess(function(response){
                context.DataAttachment.data.folder = response.data.data.folder;
                context.DataAttachment.data.file = response.data.data.file;
                context.folderId = id;
                context.previousFolder = response.data.data.parent.parent_code;
            })
            .onFinish(function() {
                context.requestCalls--;
            })
            .call()
            
        },
        detail(id){
            this.requestCalls++;

            let context = this;
            // let previousFolder = null
            Api(context, smartcatAttachment.detail_folder(id)).onSuccess(function(response){
                context.DataAttachment.data.folder = response.data.data.folder;
                context.DataAttachment.data.file = response.data.data.file;
                context.folderId = id;
                context.previousFolder = response.data.data.parent.parent_code;
            })
            .onFinish(function() {
                context.requestCalls--;
            })
            .call()
            
        },
        querySearchItemName(query) {
            if (query.length > 3) {
                clearTimeout(this.selectSearch.loadTimeout);             
                let context = this;   

                this.selectSearch.sitLoading = true;
                
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, standardItemName.get(null, {per_page: 'none',item_name: query})).onSuccess(function(response) {
                        context.standardItemName = response.data.data.data.data;    
                        context.selectSearch.sitLoading = false;                                           
                    }).onError(function(error) {
                        context.standardItemName = [];
                        context.selectSearch.sitLoading = false;
                    }).call();             
                }, 200)
            }
        },
        querySearchManCode(query){
            if(query.length > 1){
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;

                this.selectSearch.mancodeLoading = true;
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, manufacture.get(null, { per_page:'none',search:query})).onSuccess(function(response){
                        context.masterManufacture = response.data.data.data.data;
                        context.selectSearch.mancodeLoading = false;
                    }).onError(function(error){
                        if(error.response.status == 404){
                            context.masterManufacture = [];
                            context.selectSearch.mancodeLoading = false;
                        }
                    }).call()
                }, 200)
            }
        },
        querySearchEquipment(query) {
            if (query.length > 2) {
                clearTimeout(this.selectSearch.loadTimeout);             
                let context = this;   

                this.selectSearch.equipmentLoading = true;
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, equipmentCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
                        context.equipmentList = response.data.data.data.data;
                        context.selectSearch.equipmentLoading = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentList = [];
                            context.selectSearch.equipmentLoading = false;
                        }
                    })
                    .call()        
                }, 200)
            }
        },
        querySearchDrawingNumber(query) {
            if (query.length > 2) {
                clearTimeout(this.selectSearch.loadTimeout);             
                let context = this;   

                this.selectSearch.drawingNumLoading = true;
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, drawingMaster.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
                        context.drawingMaster = response.data.data.data.data;
                        context.selectSearch.drawingNumLoading = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.drawingMaster = [];
                            context.selectSearch.drawingNumLoading = false;
                        }
                    })
                    .call()        
                }, 200)
            }
        },
        querySearchMtype(query) {
            clearTimeout(this.selectSearch.loadTimeout);             
            let context = this;   

            this.selectSearch.mtLoading = true;
            this.selectSearch.loadTimeout = setTimeout(() => {
                Api(context, materialType.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
                    context.materialType = response.data.data.data.data;
                    context.selectSearch.mtLoading = false;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialType = [];
                        context.selectSearch.mtLoading = false;
                    }
                })
                .call()        
            }, 200)
        },
        getManrefType(){
            let context = this;

            Api(context, manrefType.get(null, {per_page:'none'}))
            .onSuccess(function(response){
                context.manrefType = response.data.data.data.data;
            })
            .onError(function(error){
                if(error.response.status == 404){
                    context.manrefType = []
                }
            }).call();
        },
        filter() {
            let context = this;
            // context.onLoad = true;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get(0)
            }, 100);
        },
        new_link(){
            this.formLink.add = true;
            this.formLink.title = this.tt('add_new_link');
            this.formLink.show = true
        },
        new_folder(){
            this.formFolder.add = true;
            this.formFolder.title = this.tt('add_new_folder');
            this.formFolder.show = true;
        },
        rename_folder(id, old_name){
            this.formFolder.show = true;
            this.formFolder.title = this.tt('rename_folder');
            this.smartcatAttachment.folder.id = id;
            this.smartcatAttachment.folder.folder_name = old_name;
        },
        delete_folder(id){
            this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                let context = this;
                if(result.value){
                    Api(context, smartcatAttachment.delete_folder(id)).onSuccess(function(response){
                        context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                        });
                        context.detail(context.folderId);
                    }).call();
                }
            })
        },
        new_file(){
            this.formFile.add = true;
            this.formFile.title = this.tt('add_new_file');
            this.formFile.show = true;
        },
        delete_file(id){
            this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                let context = this;
                if(result.value){
                    Api(context, smartcatAttachment.delete_file(id)).onSuccess(function(response){
                        context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                        });
                        context.detail(context.folderId);
                    }).call();
                }
            })
        },
        rename_file(id,old_name,type_of_file){
            if(type_of_file == 'link'){
                this.formLink.title = this.tt('rename_file');
                this.formLink.show = true;
                this.formLink.add = false;
                this.smartcatAttachment.link.file_attachment_name = old_name;
            }else{
                this.formFile.title = this.tt('rename_file');
                this.formFile.show = true;
                this.formFile.add = false;
                this.smartcatAttachment.file.file_attachment_name = old_name;
            }
            this.renameFile.file.file_attachment_code = id;
            this.renameFile.file.old_name = old_name;
            this.renameFile.file.type_of_file = type_of_file;
        },
        handleTableAction(command){
            switch(command.action){
                case 'new_folder' : 
                    this.new_folder(command.data);
                    break;
                case 'new_file' :
                    this.new_file(command.data);
                    break;
                case 'file_name_option':
                    this.file_name_option(command.data);
                    break;
                case 'delete_file':
                    this.delete_file(command.data);
                    break;
                case 'rename_file':
                    this.rename_file(command.data,command.old_name, command.type_of_file);
                    break;
                case 'rename_folder':
                    this.rename_folder(command.id, command.name);
                    break;
                case 'delete_folder':
                    this.delete_folder(command.data);
                    break;
                case 'copy_link':
                    // this.formCopyLink.show = true;
                    if(command.type == 'link'){
                        this.$emit('chooseAttachment', command.data);
                        // this.urlAttachment = command.data;
                    } else {
                        this.$emit('chooseAttachment', this.apiUrl + 'smartcat_attachment/' + command.data);
                        // this.urlAttachment = this.apiUrl + 'smartcat_attachment/' + command.data;
                    }
                    break;
                case 'new_link':
                    this.new_link(command.data);
                    break;
                default:
                    break;
            }
        },
        filesChange(files) {
            this.smartcatAttachment.file.file = files[0];
        },
        file_name_option(data) {
            this.fileNameOption = data;
        },
        saveLink(){
            this.btnSaveLink.onloading = true;
            let api = null;
            let context = this;

            if(this.folderId == undefined){
                this.smartcatAttachment.link.folder_attachment_code  = 0;
            }else{
                this.smartcatAttachment.link.folder_attachment_code  = this.folderId;
            }

            let formData = new FormData();
            formData.append("file_attachment_name", this.smartcatAttachment.link.file_attachment_name);
            formData.append("type_of_file",'link');
            formData.append("folder_attachment_code",this.folderId);

            if(this.formLink.add){
                api = Api(context, smartcatAttachment.upload_file(formData));
            }else{
                let context = this;
                let idAttachment = this.renameFile.file.file_attachment_code;
                api = Api(context, smartcatAttachment.rename_file(idAttachment,{ file_attachment_name: context.smartcatAttachment.link.file_attachment_name, old_name: context.renameFile.file.old_name, type_of_file: 'link'}));
            }
            api.onSuccess(function(response) {
                 context.$notify({
                    message : response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.formLink.show = false;
                context.detail(context.folderId);
            }).onError(function(error){
                context.errorMessage = error.response.data;
            }).
            onFinish(function(){
                context.btnSaveLink.onLoading = false;
                context.smartcatAttachment.link = {};
            }).call();

        },
        saveFile() {
            this.btnSaveFile.onLoading = true;
            let api = null;
            let context = this;
            let formData = new FormData();
            let data = {};
            if(this.folderId == undefined){
                this.smartcatAttachment.file.folder_attachment_code = 0
            }else{
                this.smartcatAttachment.file.folder_attachment_code = this.folderId
            }

            if(this.fileNameOption == 'freetext'){
                this.smartcatAttachment.file.file_attachment_name = this.smartcatAttachment.file.file_attachment_name;
            }else if (this.fileNameOption == 'oem') {
                    this.smartcatAttachment.file.file_attachment_name = this.smartcatAttachment.file.equipment_name+' - '+this.smartcatAttachment.file.manufacture_code+' - '+this.smartcatAttachment.file.model_or_type+' - '+this.smartcatAttachment.file.part_number_or_drawing;
            } else {
                this.smartcatAttachment.file.file_attachment_name = this.smartcatAttachment.file.inc+' - '+this.smartcatAttachment.file.mfr_code+' - '+this.smartcatAttachment.file.manref;
            }

            if(this.formFile.add){
                formData.append('folder_attachment_code', this.smartcatAttachment.file.folder_attachment_code);
                formData.append('file_attachment_name',this.smartcatAttachment.file.file_attachment_name);
                formData.append('file', this.smartcatAttachment.file.file);
            }else{
                var idAttachment = this.renameFile.file.file_attachment_code;
                data = {
                    file_name_attachment : this.smartcatAttachment.file.file_attachment_name,
                    old_name : this.renameFile.file.old_name,
                    type_of_file : this.renameFile.file.type_of_file
                };
            }

            if(this.formFile.add){
                api = Api(context, smartcatAttachment.upload_file(formData));
            }else{
                let context = this;
                api = Api(context, smartcatAttachment.rename_file(idAttachment,{ file_attachment_name: context.smartcatAttachment.file.file_attachment_name, old_name: context.renameFile.file.old_name, type_of_file: context.renameFile.file.type_of_file}));
            }

            api.onSuccess(function(response){
                context.$notify({
                    message : response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.formFile.show = false;
                context.detail(context.folderId);
            }).
            onError(function(error){
                context.errorMessage = error.response.data;
            }).
            onFinish(function(){
                context.btnSaveFile.onLoading = false;
                context.smartcatAttachment.file = {};
                context.smartcatAttachment.file.file = {};
                context.$refs.file.value=null;
            }).call()
        },
        saveFolder(){
            this.btnSaveFolder.onLoading = true;
            let api = null;
            let context = this;

            if(this.folderId == undefined){
                this.smartcatAttachment.folder.parent_code = 0
            }else{
                this.smartcatAttachment.folder.parent_code = this.folderId;
            }

            if(this.formFolder.add){
                api = Api(context, smartcatAttachment.create_folder(this.smartcatAttachment.folder));
            }else{
                api = Api(context, smartcatAttachment.rename_folder(context.smartcatAttachment.folder.id, { folder_name : context.smartcatAttachment.folder.folder_name}));
            }

            api.onSuccess(function(response){
                context.$notify({
                    message : response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.formFolder.show = false;
                context.detail(context.smartcatAttachment.folder.parent_code);
            }).onError(function(error) {                    
                context.errorMessage = error.response.data;
            }).onFinish(function() {
                context.btnSaveFolder.onLoading = false; 
                context.smartcatAttachment.folder = {};
            })
            .call()
        },
        copy_link() {
            var url = this.$refs.attachmentLink;

            url.select();
    
            document.execCommand("copy");
        }
    }
};
</script>